"use client";

import { useScrollTop } from "@/hooks/use-scroll-top";
import { cn } from "@/lib/utils";
import { Logo } from "./logo";
import { Button } from "@/components/ui/button";
import Link from "next/link";

import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuList,
    NavigationMenuTrigger
} from "@/components/ui/navigation-menu";
import React from "react";
import { NavigationBarMenuElementModel, NavigationBarModel } from "@/lib/models/NavigationBarModel";
import { useContentfulLiveUpdates } from "@contentful/live-preview/react";
import { ContentfulLivePreview } from "@contentful/live-preview";
import { Menu } from "lucide-react";
import { Drawer, DrawerTrigger, DrawerContent, DrawerHeader, DrawerTitle, DrawerDescription, DrawerFooter, DrawerClose } from "@/components/ui/drawer";
import { ScrollArea } from "@/components/ui/scroll-area";


export const NavbarComponent = ({ navigationBarModel }: { navigationBarModel: NavigationBarModel | null; }) => {
    const scrolled = useScrollTop();

    const updatedNavigationBarModel = useContentfulLiveUpdates(navigationBarModel);

    if (updatedNavigationBarModel == null) {
        return null;
    }

    return (
        <div
            className={cn(
                "z-50 bg-background-900 fixed top-0 flex flex-col items-center w-full ",
                scrolled && "border-b shadow-sm"
            )}>
            <div className="hidden lg:flex justify-between items-center w-full max-w-[1440px] p-6">
                {updatedNavigationBarModel && updatedNavigationBarModel.logo && updatedNavigationBarModel.logo.url && (
                    <div>
                        <Link href={"/"} >
                            <img src={updatedNavigationBarModel.logo.url} className="h-[40px]" />
                        </Link>
                    </div>

                )}
                <div
                    {...ContentfulLivePreview.getProps({
                        entryId: updatedNavigationBarModel.sys.id,
                        fieldId: "navigationBar",
                        locale: "en-US",
                    })}
                    className="md:ml-auto md:justify-end justify-between w-full flex items-center gap-x-2">
                    {updatedNavigationBarModel && updatedNavigationBarModel.menuItemsCollection && updatedNavigationBarModel.menuItemsCollection.items.map((menuItem) => {
                        if (!menuItem || !menuItem.menuElementsCollection || menuItem.menuElementsCollection.total < 1) {
                            return null;
                        }

                        if (menuItem.menuElementsCollection.total == 1) {
                            const menuElement = menuItem.menuElementsCollection.items[0] as NavigationBarMenuElementModel;
                            return (
                                <Button
                                    {...ContentfulLivePreview.getProps({
                                        entryId: menuItem.sys.id,
                                        fieldId: "navigationBar",
                                        locale: "en-US",
                                    })}
                                    key={menuElement.sys.id}
                                    variant="darkText"
                                    size="sm">
                                    <Link href={menuElement.slug}>{menuElement.pageTitle}</Link>
                                </Button>
                            )
                        }

                        if (menuItem.menuElementsCollection.total > 1) {
                            return (
                                <NavigationMenu
                                    {...ContentfulLivePreview.getProps({
                                        entryId: menuItem.sys.id,
                                        fieldId: "navigationBar",
                                        locale: "en-US",
                                    })}
                                    key={menuItem.sys.id}>
                                    <NavigationMenuList>
                                        <NavigationMenuItem>
                                            <NavigationMenuTrigger
                                                className="font-normal text-secondary-200 disabled:opacity-40"
                                            >{menuItem.menuTitle}</NavigationMenuTrigger>
                                            <NavigationMenuContent>
                                                <ul className="flex flex-col gap-3 p-2 md:w-[300px] lg:w-[300px] font-light">
                                                    {menuItem && menuItem.menuElementsCollection &&
                                                        menuItem.menuElementsCollection.items &&
                                                        menuItem.menuElementsCollection.items.map((menuElement) => {
                                                            return (
                                                                <li key={menuElement.sys.id}>
                                                                    <Link
                                                                        href={menuElement.slug}
                                                                        className="block select-none space-y-1 rounded-md py-4 px-3 leading-none no-underline outline-none transition-colors hover:bg-secondary-200 hover:text-primary-900 focus:bg-accent focus:text-accent-foreground">
                                                                        <div className="text-sm font-medium leading-none">{menuElement.pageTitle}</div>
                                                                        {/* <p className="line-clamp-2 text-sm leading-snug text-secondary-200 text-opacity-80 ">
                                                                        {menuElement.pageDescription}
                                                                    </p> */}
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })}
                                                </ul>
                                            </NavigationMenuContent>
                                        </NavigationMenuItem>
                                    </NavigationMenuList>
                                </NavigationMenu>
                            )
                        }

                    })}
                    
                    <Link href="/signup">
                        <Button variant={"darkFilled"} size={"md"} key={"signup_button"}>
                            Sign Up
                        </Button>
                    </Link>
                    <a href={process.env.NEXT_PUBLIC_LEGACY_REDIRECT_URL ?? 'https://www.cmnavigator.com/redirect/'}>
                        <Button 
                            className="ml-4"
                            variant={"darkOutline"} 
                            size={"md"} 
                            key={"signin_button"}>
                            Sign In
                        </Button>
                    </a>
                </div>
            </div>
            <div className="lg:hidden flex flex-row h-[88px] w-full px-6">
                <div className="flex flex-row items-center">
                    <Link href={"/"} >
                        <img src={updatedNavigationBarModel.logo.url} className="h-[40px]" />
                    </Link>
                </div>
                <div className="flex flex-row items-end justify-end w-full" >
                    <Drawer>
                        
                        <DrawerTrigger>
                            <div className="flex flex-col items-center justify-center h-[88px] ">
                                <Menu size={24} className="m-6" />
                            </div>

                        </DrawerTrigger>
                        <DrawerContent className="bg-grey-900 flex flex-col justify-start items-start w-full h-screen ">
                            <ScrollArea className="w-full h-full top-0 bottom-0 ">
                                <div className="mt-[100px]"></div>
                                {updatedNavigationBarModel && updatedNavigationBarModel.menuItemsCollection && updatedNavigationBarModel.menuItemsCollection.items.map((menuItem) => {
                                    return (
                                        <DrawerHeader key={menuItem.sys.id} className="w-full flex flex-col items-start">
                                            <DrawerTitle className="text-secondary-200 w-full px-2 text-left text-[24px]">{menuItem.menuTitle}</DrawerTitle>
                                            {menuItem.menuElementsCollection.items.map((menuElement) => {
                                                return (
                                                    <Link
                                                        key={menuElement.sys.id}
                                                        href={menuElement.slug}
                                                        className="block select-none rounded-md py-3 px-10 leading-none transition-colors focus:bg-accent focus:text-accent-foreground">
                                                        <div className="text-[20px] font-regular leading-none">{menuElement.pageTitle}</div>
                                                        {/* <p className="line-clamp-2 text-sm leading-snug text-secondary-200 text-opacity-80 ">
                                                                        {menuElement.pageDescription}
                                                                    </p> */}
                                                    </Link>
                                                )
                                            })}
                                        </DrawerHeader>
                                    )
                                })}
                                <DrawerHeader className="w-full flex flex-col items-start ">
                                    <DrawerTitle className="text-secondary-200 w-full px-2 text-left text-[24px]">Home</DrawerTitle>
                                    <Link
                                        href="/"
                                        className="block select-none rounded-md py-3 px-10 leading-none transition-colors focus:bg-accent focus:text-accent-foreground">
                                        <div className="text-[20px] font-regular leading-none">Home</div>
                                        {/* <p className="line-clamp-2 text-sm leading-snug text-secondary-200 text-opacity-80 ">
                                                        {menuElement.pageDescription}
                                                    </p> */}
                                    </Link>
                                </DrawerHeader>
                                <DrawerFooter className="w-full">
                                <Link href="/signup">
                                    <Button 
                                        className="w-full"
                                        variant={"darkFilled"} 
                                        size={"lg"} 
                                        key={"signup_button"}>
                                        Sign Up
                                    </Button>
                                </Link>
                                <a href={process.env.NEXT_PUBLIC_LEGACY_REDIRECT_URL ?? 'https://www.cmnavigator.com/redirect/'}>
                                    <Button 
                                        className="w-full mt-4"
                                        variant={"darkOutline"} 
                                        size={"lg"} 
                                        key={"signin_button"}>
                                        Sign In
                                    </Button>
                                </a>
                                    <DrawerClose>
                                        <Button className="w-full mt-4" variant="darkText">Close</Button>
                                    </DrawerClose>
                                </DrawerFooter>

                            </ScrollArea>

                        </DrawerContent>
                    </Drawer>
                </div>
            </div>
        </div>
    )
}
import * as React from "react"
import {Slot} from "@radix-ui/react-slot"
import {cva, type VariantProps} from "class-variance-authority"

import {cn} from "@/lib/utils"

const buttonVariants = cva(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    {
        variants: {
            variant: {
                highlightFilled:
                    "bg-primary-900 text-secondary-200 hover:bg-opacity-70 disabled:bg-opacity-40",
                lightFilled:
                    "bg-primary-900 text-secondary-200 hover:bg-grey-900 disabled:bg-opacity-40",
                darkFilled:
                    "bg-primary-200 text-grey-900 hover:bg-primary-100 disabled:bg-opacity-40",

                highlightOutline:
                    "border border-primary-900 text-primary-900 hover:bg-secondary-100 hover:bg-opacity-70 hover:border-opacity-10 hover:border-primary-900 disabled:opacity-40 disabled:border-opacity-40",
                lightOutline:
                    "border border-primary-900 text-primary-900 hover:bg-secondary-100 disabled:opacity-40 disabled:border-opacity-40",
                darkOutline:
                    "border border-secondary-200 text-secondary-200 hover:bg-primary-200 hover:text-primary-900 hover:border-opacity-0 disabled:border-opacity-40 disabled:opacity-40",

                highlightText:
                    "text-primary-900 hover:underline hover:underline-offset-4 disabled:opacity-40",
                lightText:
                    "text-primary-900 hover:underline hover:underline-offset-4 disabled:opacity-40",
                darkText:
                    "text-secondary-200 hover:underline hover:underline-offset-4 disabled:opacity-40",
            },
            size: {
                sm: "px-5 py-2",
                md: "px-8 py-2",
                lg: "px-6 py-4",
                snug: "p-0",
                icon: "h-10 w-10",
            },
        },
        defaultVariants: {
            variant: "highlightFilled",
            size: "lg",
        },
    }
)

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({className, variant, size, asChild = false, ...props}, ref) => {
        const Comp = asChild ? Slot : "button"
        return (
            <Comp
                className={cn(buttonVariants({variant, size, className}))}
                ref={ref}
                {...props}
            />
        )
    }
)
Button.displayName = "Button"

export {Button, buttonVariants}
